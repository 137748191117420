import * as React from 'react';
import { useRef, useState, useEffect, useContext } from 'react';
import { ColorModeContext, tokens } from "../theme";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from 'react-router-dom';
import DialpadIcon from '@mui/icons-material/Dialpad';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import InsightsIcon from '@mui/icons-material/Insights';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import AddTaskIcon from '@mui/icons-material/AddTask';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import Tooltip from '@mui/material/Tooltip';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import { useNavigate } from 'react-router-dom';
import jwt from "jwt-decode";
import logoImage from '../images/hioi.png';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { green } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import CancelIcon from '@mui/icons-material/Cancel';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import CoffeeIcon from '@mui/icons-material/Coffee';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import BoltIcon from '@mui/icons-material/Bolt';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';



export default function MiniDrawer() {

  const api = axios.create({baseURL: global_link});
  const [drawerWidth,setDrawerWidth] = useState(180);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [calltabsDisplay, setCalltabsDisplay] = useState('');
  const [userLevel, setUserLevel] = useState('');
  const [userName, setName] = useState('');
  const [userExtension, setExtension] = useState('');
  const valueRef = useRef(1);
  const callDisRef = useRef('');
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const [openNotification, setNotification] = useState(false);

  // openNotificationModal


  const userInfo = () => {
    api.get('/user/', {
        withCredentials: true,
      }).then(response => {
          setName(response.data.user_name);
          // setEmail(response.data.user_email);
          // setPhone(response.data.user_phone);
          // setLevel(response.data.user_level);
          setExtension(response.data.user_extension);
          setUserLevel(response.data.user_level);
        })
        .catch(error => {
          console.error(error);
        });}
        
        useEffect(() => {
          userInfo();
        }, []);

const openedMixin = (theme) => ({
  backgroundColor: `${colors.primary[700]}!important`,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  border:'none!important',
});

const closedMixin = (theme) => ({
  backgroundColor: `${colors.primary[700]}!important`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  border:'none!important',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  background: `${colors.primary[700]}`,
  // backgroundColor: `${colors.primary[600]}!important`,
  zIndex: theme.zIndex.drawer + 1,
  boxShadow:'none',
  borderBottom:`none!important`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    border:'none!important',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
  

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const editDialerDrawer = () => {
    valueRef.current = valueRef.current === 0 ? 290 : 0;
    callDisRef.current = callDisRef.current === 'none' ? '' : 'none'; 
    const event = new CustomEvent('Edit_Dialer_Drawer', { detail: valueRef.current});
    window.dispatchEvent(event);
    setCalltabsDisplay(callDisRef.current)
  };


  useEffect(() => {
const tabsDisplay = (event) => {
  setCalltabsDisplay(event.detail);
};
window.addEventListener('Display_Call_Icons', tabsDisplay);
return () => {
    window.removeEventListener('Display_Call_Icons',tabsDisplay);
};
}, []);
  
  const location = useLocation();

  const isActiveRoute = (pathname) => {
    return location.pathname === pathname;
  };


  const tabChange = (tab_no) => {
    const event = new CustomEvent('tabChange', { detail: tab_no });
    window.dispatchEvent(event);
  };

  const accessToken = localStorage.getItem('access_token');
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const handleLogout = () => {
    api.get('/userlogout/',{
      headers,
      withCredentials: true,
    })
      .then(response => {
        localStorage.setItem("loggedIn",false);
        localStorage.removeItem('access_token');
        localStorage.removeItem("calldisabled");
        sessionStorage.removeItem("calldisabled");
        sessionStorage.removeItem('get_register');
        window.location.href="/login";
      })
      .catch(error => {
        console.log(error);
      });

      unRegister();
  };

  const unRegister = () => {
    const event = new CustomEvent('unregisterSession', { detail: 'unregister' });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decodedToken = jwt(token);
      const userLevel = decodedToken.user_level;
      setUserLevel(userLevel);
    }
  }, []);

  // drop down

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const modalStyle =  {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };
  


  return (
    <Box sx={{ display: 'flex' }}>
      {/* <Expiry /> */}
      <AppBar position="fixed" open={open}>
        <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
              padding: '21px !important',marginLeft:'-15px',marginTop:'5px', backgroundImage: `url(${logoImage})`,backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',backgroundPosition: 'center',height: "auto",
            }}
          >
            {/* <MenuIcon /> */}

            
          </IconButton>
        </Typography>
        {/* <Expiry /> */}
        <Tooltip title="User Level" arrow>
        <Button component={Link} to="/profile" color="inherit" sx={{fontWeight:"600 !important" }}> {userName} - {userExtension} | {userLevel}</Button>
        </Tooltip>

        {/* <Tooltip title="System Manual" arrow>
        <IconButton component={Link} to="/manual" color="inherit" ><MenuBookIcon/></IconButton>
        </Tooltip> */}
        
        <IconButton color="inherit">|</IconButton>
 
        {userLevel === "agent" && (
        <Box display={calltabsDisplay}>
{/* 
<Tooltip title="Notifications" arrow>
        <IconButton onClick={() => setNotification(true)} color="inherit">
          <NotificationsActiveIcon/> 
          <IconButton sx={{backgroundColor:'red',fontSize:'13px',fontWeight:'600',padding:'6px',marginLeft:'-10px',color:'white'}}>90</IconButton>
        </IconButton>
        </Tooltip> */}


        <Tooltip title="Dialer" arrow>
        <IconButton onClick={() => tabChange(0)} color="inherit">
          <AddIcCallIcon/>
        </IconButton>
        </Tooltip>
        <Tooltip title="Call History" arrow>
        <IconButton onClick={() => tabChange(1)} color="inherit">
          <HistoryIcon/>
        </IconButton>
        </Tooltip>
        <Tooltip title="Contacts" arrow>
        <IconButton onClick={() => tabChange(2)} color="inherit">
          <ContactsOutlinedIcon/>
        </IconButton>
        </Tooltip>
        {/* <Tooltip title="Power Dialer" arrow>
        <IconButton onClick={() => tabChange(4)} color="inherit" ><ElectricBoltIcon/></IconButton>
        </Tooltip> */}
        <Tooltip title="Call Settings" arrow>
        <IconButton onClick={() => tabChange(3)} color="inherit">
          {/* <PhonelinkSetupIcon /> */}
          <SettingsIcon/>
        </IconButton>
        </Tooltip>
        </Box>
)}
{userLevel === "supervisor" && (
          <Box display={calltabsDisplay}>
          <Tooltip title="Dialer" arrow>
          <IconButton onClick={() => tabChange(0)} color="inherit">
            <AddIcCallIcon/>
          </IconButton>
          </Tooltip>
          <Tooltip title="Call History" arrow>
          <IconButton onClick={() => tabChange(1)} color="inherit">
            <HistoryIcon/>
          </IconButton>
          </Tooltip>
          <Tooltip title="Contacts" arrow>
          <IconButton onClick={() => tabChange(2)} color="inherit">
            <ContactsOutlinedIcon/>
          </IconButton>
          </Tooltip>
          <Tooltip title="Call Settings" arrow>
          <IconButton onClick={() => tabChange(3)} color="inherit">
          <SettingsIcon/>
          </IconButton>
          </Tooltip>
          </Box>
)}

        <Tooltip title="Theme" arrow>
        <IconButton onClick={colorMode.toggleColorMode} color="inherit">
          {theme.palette.mode === "dark" ? (
           <LightModeOutlinedIcon color="inherit" />
          ) : (
            
            <DarkModeOutlinedIcon color="inherit" />
          )}
        </IconButton>
        </Tooltip>

      </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} sx={{backgroundColor:`${colors.primary[900]}!important`,color:'inherit !important'}}>
        <DrawerHeader sx={{color:'inherit important'}}>
          <Box display='flex' marginLeft='-40px!important' >
          <Typography marginLeft='-40px!important' variant='h6' fontWeight='700'>CALL SUITE</Typography>
          </Box>
       
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon sx={{fontWeight:'bold'}} /> : <ChevronLeftIcon sx={{fontWeight:'700'}}/>}
          </IconButton>
        </DrawerHeader>
        
        
        <List>
        

        <Modal
        open={openNotification}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Notitifactions</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setNotification(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
      
          <Grid container spacing={1}>
<Grid item xs={12}>

</Grid>


            </Grid>
        </div>
        </Box>
        
      </Modal>


        {userLevel === "admin" && (


<ListItem disablePadding sx={{ display: 'block',fontFamily: 'Source Sans Pro", sans-serif'   }} >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                color="inherit"
                to="/"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                 <HomeOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='Dashboard' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/callogs') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/callogs"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <PhoneCallbackIcon />
                </ListItemIcon>
                <ListItemText primary='Call Logs' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/dispositions') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/dispositions"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                   <AllInboxIcon />
                </ListItemIcon>
                <ListItemText primary='Dispositions' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/contacts') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/contacts"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <ContactsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='Contacts' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/monitor') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/monitor"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <RecordVoiceOverIcon />
                </ListItemIcon>
                <ListItemText primary='Monitoring' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/ticketing') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/ticketing"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <AddTaskIcon />
  </ListItemIcon>
  <ListItemText primary='Ticketing' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/script') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/script"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <TextSnippetIcon/>
  </ListItemIcon>
  <ListItemText primary='Agent Script' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>



<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/knowledgebase') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/knowledgebase"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <HelpOutlineIcon/>
  </ListItemIcon>
  <ListItemText primary='Knowledge Base' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/reports') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/reports"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <InsightsIcon />
                </ListItemIcon>
                <ListItemText primary='Reports' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/analytics') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/analytics"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <InsertChartIcon />
                </ListItemIcon>
                <ListItemText primary='Analytics' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/email') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/email"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary='Email' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>


             <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/sms') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/sms"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <ChatBubbleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary='SMS' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

              <ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/breaks') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/breaks"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <CoffeeIcon />
  </ListItemIcon>
  <ListItemText primary='Breaks' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/settings') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/settings"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <SettingsIcon/>
                </ListItemIcon>
                <ListItemText primary='Settings' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

              <Divider sx={{ backgroundColor: colors.primary[900]}} />

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/profile') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/profile"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <PersonOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='Profile' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

              

              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={handleLogout}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

            </ListItem>
            )}


{userLevel === "supervisor" && (

<ListItem disablePadding sx={{ display: 'block',fontFamily: 'Source Sans Pro", sans-serif'   }} >

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  color="inherit"
  to="/"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
   <HomeOutlinedIcon />
  </ListItemIcon>
  <ListItemText primary='Dashboard' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>
<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/callogs') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/callogs"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <PhoneCallbackIcon />
  </ListItemIcon>
  <ListItemText primary='Call Logs' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/dispositions') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/dispositions"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
     <AllInboxIcon />
  </ListItemIcon>
  <ListItemText primary='Dispositions' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/contacts') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/contacts"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <ContactsOutlinedIcon />
  </ListItemIcon>
  <ListItemText primary='Contacts' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/ticketing') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/ticketing"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <AddTaskIcon />
  </ListItemIcon>
  <ListItemText primary='Ticketing' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/recordings') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/recordings"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <GraphicEqIcon />
  </ListItemIcon>
  <ListItemText primary='Recordings' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

{/* <ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/monitor') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/monitor"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <RecordVoiceOverIcon />
  </ListItemIcon>
  <ListItemText primary='Monitoring' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>  */}

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/script') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/script"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <TextSnippetIcon/>
  </ListItemIcon>
  <ListItemText primary='Agent Script' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/chat') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/chat"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <QuestionAnswerIcon />
  </ListItemIcon>
  <ListItemText primary='Chat' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/whatsapp') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/whatsapp"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <WhatsAppIcon />
  </ListItemIcon>
  <ListItemText primary='WhatsApp' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/email') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/email"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <EmailIcon />
  </ListItemIcon>
  <ListItemText primary='Email' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/sms') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/sms"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <ChatBubbleOutlineIcon />
  </ListItemIcon>
  <ListItemText primary='SMS' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/breaks') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/breaks"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <CoffeeIcon />
  </ListItemIcon>
  <ListItemText primary='Breaks' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/reports') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/reports"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <InsightsIcon />
  </ListItemIcon>
  <ListItemText primary='Reports' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/analytics') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/analytics"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <InsertChartIcon />
                </ListItemIcon>
                <ListItemText primary='Analytics' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>

<ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: isActiveRoute('/supsettings') ? colors.primary[600] : 'transparent',
                }}
                component={Link} 
                to="/supsettings"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:'inherit',
                  }}
                >
                  <SettingsIcon/>
                </ListItemIcon>
                <ListItemText primary='Settings' sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>


<Divider sx={{ backgroundColor: colors.primary[900]}} />

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/profile') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/profile"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <PersonOutlinedIcon />
  </ListItemIcon>
  <ListItemText primary='Profile' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
  }}
  onClick={handleLogout}
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <ExitToAppIcon />
  </ListItemIcon>
  <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

</ListItem>

 )}


{userLevel === "agent" && ( 

<ListItem disablePadding sx={{ display: 'block',fontFamily: 'Source Sans Pro", sans-serif'   }} >

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  color="inherit"
  to="/"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
   <HomeOutlinedIcon />
  </ListItemIcon>
  <ListItemText primary='Dashboard' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>
<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/callogs') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/callogs"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <PhoneCallbackIcon />
  </ListItemIcon>
  <ListItemText primary='Call Logs' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/dispositions') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/dispositions"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
     <AllInboxIcon />
  </ListItemIcon>
  <ListItemText primary='Dispositions' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/contacts') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/contacts"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <ContactsOutlinedIcon />
  </ListItemIcon>
  <ListItemText primary='Contacts' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/ticketing') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/ticketing"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <AddTaskIcon />
  </ListItemIcon>
  <ListItemText primary='Ticketing' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/script') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/script"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <TextSnippetIcon/>
  </ListItemIcon>
  <ListItemText primary='Agent Script' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/whatsapp') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/whatsapp"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <WhatsAppIcon />
  </ListItemIcon>
  <ListItemText primary='WhatsApp' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/email') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/email"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <EmailIcon />
  </ListItemIcon>
  <ListItemText primary='Email' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>


<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/sms') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/sms"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <ChatBubbleOutlineIcon />
  </ListItemIcon>
  <ListItemText primary='SMS' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/chat') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/chat"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <QuestionAnswerIcon />
  </ListItemIcon>
  <ListItemText primary='Chat' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<Divider sx={{ backgroundColor: colors.primary[900]}} />

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
    backgroundColor: isActiveRoute('/profile') ? colors.primary[600] : 'transparent',
  }}
  component={Link} 
  to="/profile"
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <PersonOutlinedIcon />
  </ListItemIcon>
  <ListItemText primary='Profile' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

<ListItemButton
  sx={{
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
  }}
  onClick={handleLogout}
>
  <ListItemIcon
    sx={{
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
      color:'inherit',
    }}
  >
    <ExitToAppIcon />
  </ListItemIcon>
  <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0 }} />
</ListItemButton>

</ListItem>

)}

        </List>
       

      </Drawer>
      <Box component="main">
        <DrawerHeader/>
      </Box>


{userLevel === "agent" && (
  <Button variant="contained"  sx={{ borderRadius: "100px !important", border: `3px solid ${colors.greenAccent[500]}`,height:"77px", backgroundColor: "transparent !important",
  fontSize: "104px !important",position:"fixed",marginTop:"87vh!important",right:'20px',zIndex:9999}} onClick={editDialerDrawer} >
<IconButton sx={{backgroundColor: "transparent!important", color:'inherit !important',}}>
<DialpadIcon/>
</IconButton>
</Button>
)}
{userLevel === "supervisor" && (
<Button variant="contained"  sx={{ borderRadius: "100px !important", border: `3px solid ${colors.greenAccent[500]}`,height:"77px", backgroundColor: "transparent !important",
fontSize: "104px !important",position:"fixed",marginTop:"87vh!important",right:'20px',zIndex:9999}} onClick={editDialerDrawer} >
<IconButton sx={{backgroundColor: "transparent!important", color:'inherit !important',}}>
<DialpadIcon/>
</IconButton>
</Button>
)}

    </Box>
       
  );
}
